<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col class="mt-2" md="10" sm="8">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Blog</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table
          :items="items"
          :fields="fields"
          show-empty
          responsive
          :busy="dataLoading"
        >
          <template #table-busy>
            <div class="text-center my-5">
              <b-spinner
                class="align-middle"
                type="grow"
                variant="primary"
                style="width: 3.5rem; height: 3.5rem;"
              ></b-spinner>
            </div>
          </template>

          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(image)="data">
            <b-img
              :src="data.value"
              style="width: 100px; height: 100px; object-fit: cover"
              rounded
              alt="image"
            />
          </template>

          <template #cell(actions)="data">
            <template>
              <!-- <b-button
                v-if="data.item.status == 'active'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="mr-1 mb-50 btn-icon"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.left
                title="Inactive"
                size="16"
                @click="ChangeStatus(data.item, 'inactive')"
              >
                <feather-icon size="16" icon="XIcon" />
              </b-button>

              <b-button
                v-else
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                class="mr-1 mb-50 btn-icon"
                v-b-tooltip.hover.v-success
                v-b-tooltip.placement.left
                title="Active"
                size="16"
                @click="ChangeStatus(data.item, 'active')"
              >
                <feather-icon size="16" icon="CheckIcon" />
              </b-button> -->

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-1 mb-50"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Edit"
                @click="OpenAddModal(data.item.id)"
              >
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="mb-50 btn-icon"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete"
                size="16"
                @click="DeleteFlavour(data.item.id)"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </b-button>
            </template>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";

import VueTrix from "vue-trix";

export default {
  components: {
    //Multiselect,
    VueTrix,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    //BAvatar,
    BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
    }
  },
  data() {
    return {
      fields: [
        "#",
        { label: "image", key: "image" },
        { label: "Title", key: "title" },
        // { label: "description", key: "description" },
        "actions",
      ],
      items: [],
      searchQuery: "",
      dataLoading: false,
    };
  },
  methods: {
    OpenAddModal(id) {
      this.$router.push({
        name: "single-blog",
        params: { id: id },
      });
    },
    ChangeStatus(item, val) {
      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${val} the blogs?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          item.status = val;
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/Blogs/" + item.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: item,
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                this.LoadData();
                this.$bvToast.toast("Blog is " + item.status + " now!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/Blogs/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire("Success!", "Blog has been deleted.", "success").then(
                  (res) => {
                    this.LoadData();
                  }
                );
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    LoadData() {
      this.dataLoading = true;
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Blogs",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.items = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
